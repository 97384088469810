<!-- eslint-disable max-len -->
<template>
   <div class="side-menu" :class="{'open':menuIsOpen}">
      <div class="burger" @click="menuIsOpen=!menuIsOpen">
        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512">
          <!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
          <path
            fill="#FFF"
            d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z"
          />
        </svg>
      </div>

      <div class="side-menu-inner">
        <span class="site-name"> 人格特質<br />測驗系統 </span>
        <div class="main-item" v-for="(menu, index) in menus" :key="menu.icon">
          <a
            :href="`#menu-${index}`"
            role="button"
            :aria-controls="`#menu-${index}`"
            data-toggle="collapse"
            aria-expanded="false"
            class="had-sub-menu"
            :class="{ active: toggle[menu.Guid] }"
          >
            <!-- {{ menu.MenuName }} -->
            <template v-for="language in menu.Localizationals">
              <span
                v-if="
                  language.LocalizationTypeCode === currentLanguage.LocalizationTypeCode
                "
                :key="language.LocalizationTypeCode"
              >
                {{ language.MenuName ? language.MenuName : menu.MenuName }}
              </span>
            </template>
          </a>
          <div>
            <ul class="collapse list-unstyled" :id="`menu-${index}`">
              <li class="menu-item" v-for="item in menu.Children" :key="item.Guid">
                <div
                  class="icon-wrap"
                  :class="{ active: `${item.Link}` === $route.path }"
                >
                  <i class="el-icon-caret-right"></i>
                </div>
                <!-- :class="{ active:題庫版本維護 `${item.Link}` === $route.path }" -->
                <router-link :to="item.Link">
                  <template v-for="language in item.Localizationals">
                    <span
                      v-if="
                        language.LocalizationTypeCode ===
                        currentLanguage.LocalizationTypeCode
                      "
                      :key="language.LocalizationTypeCode"
                    >
                      {{ language.MenuName ? language.MenuName : item.MenuName }}
                    </span>
                  </template>
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
</template>

<style lang="scss" scoped>
.dropdown-toggle::after {
  border-top: 0;
}


.test {
  padding: 0;
  // width: 199px;
  padding-right: 61px;
  border: none;
}

.had-sub-menu {
  position: relative;
  cursor: pointer;
  color: #fff;
  padding: 10px 15px;
  border-radius: 25px;
  &[aria-expanded="true"] {
    background-color: #000;
    &::after {
      transform: translateY(-50%) rotate(90deg) !important;
    }
  }
}

.menu-item {
  display: flex;
  align-items: center;
  color: #fff;
  padding: 15px 0;
  padding-left: 35px;

  a {
    color: #fff;
    display: block;
    // padding:15px 15px;
    font-size: 0.9em;
  }
}

.icon-wrap {
  margin-right: 5px;
  vertical-align: middle;
  height: 20px;
  width: 20px;
  text-align: center;
  line-height: 20px;
}

.active {
  color: #cca836 !important;
}

.text-pink {
  color: pink;
}
</style>

<script>
// import { mapGetters, mapActions } from 'vuex';
import { mapState } from "pinia"; //引入映射函数
import { languageStore } from "@/stores/languageStore";

export default {
  data() {
    return {
      screen: null, // loading遮罩
      toggle: {
        system: false,
        assessmentformSetting: false,
        assessmentform: false,
      },
      menuIsOpen:false,
      menus: [],
      // pendingLength: 0,
      // selfLength: 0,
      searchInfo: {},
    };
  },
  computed: {
    // ...mapGetters('sidebarModule', ['selfLength', 'pendingLength']),
    ...mapState(languageStore, ["enabledLangOptions"]),
    ...mapState(languageStore, ["currentLanguage"]),
  },
  methods: {
    // ...mapActions('sidebarModule', ['getEvaluationself', 'getPending']),
    // clickToggle(value) {
    //   // console.log('value', value)
    //   if (value === 'system') {
    //     this.toggle.system = !this.toggle.system;
    //   } else if (value === 'setting') {
    //     this.toggle.setting = !this.toggle.setting;
    //   } else {
    //     this.toggle.evaluation = !this.toggle.evaluation;
    //   }
    // },
    getRoleMenu() {
      this.screen = this.openLoading();
      const url = `${window.BaseUrl.api}/Menu/MenuByRoleId`;

      const params = {
        roleGuid: localStorage.RoleId,
      };

      // const tree = [];
      this.$http
        .get(url, { params })
        .then((response) => {
          if (response.data.ErrorMessage) {
            this.$notify({
              title: "錯誤",
              message: response.data.ErrorMessage,
              type: "error",
              duration: 0,
            });
          } else {
            this.menus = response.data.Data;
          }
          this.screen.close();
        })
        .catch((error) => {
          this.$notify({
            title: "錯誤",
            message: error.response.data.ErrorMessage,
            type: "error",
            duration: 0,
          });
          this.screen.close();
        });
    },
    // getPending() {
    //     const url = `${window.BaseUrl.api}/evaluationpending/pendingcount`

    //     let obj = {
    //         CompId: localStorage.CompId,
    //         DeptId: localStorage.DeptId,
    //         EmpNo: localStorage.EmpNo,
    //     }

    //     // this.updateLoading(true)
    //     this.$http.post(url, obj).then((response) => {
    //         // console.log(response)
    //         // this.items = response.data
    //         this.pendingLength = response.data

    //     })
    // },
    // getEvaluationself() {
    //     const url = `${window.BaseUrl.api}/evaluationself/range`

    //     // if(!this.searchInfo.start && !this.searchInfo.end) {
    //     //     this.searchInfo.start = moment().subtract(30, 'days').format("YYYY-MM-DD")
    //     //     this.searchInfo.end = moment().add(30, 'days').format("YYYY-MM-DD")
    //     // } else if(!this.searchInfo.end) {
    //     //     this.searchInfo.end = moment().add(30, 'days').format("YYYY-MM-DD")
    //     // } else if(!this.searchInfo.start) {
    //     //     this.searchInfo.start = moment().subtract(30, 'days').format("YYYY-MM-DD")
    //     // }

    //     let end = moment().add(1, 'year').format("YYYY-MM-DD")
    //     let start = moment().subtract(1, 'year').format("YYYY-MM-DD")

    //     let params = {
    //         CompanyId: localStorage.CompId,
    //         DeptId: localStorage.DeptId,
    //         EmpNo: localStorage.EmpNo,

    //         BeginDate: start,
    //         EndDate: end,
    //     }

    //     // this.updateLoading(true)
    //     this.$http.post(url, params).then((response) => {
    //         // console.log(response.data)

    //         let arr = []

    //         response.data.forEach(item => {
    //             if(item.CompleteTime === null) {
    //                 arr.push(item)
    //             }
    //         })
    //         // let arr = response.data
    //         this.selfLength = arr.length
    //     })
    // },
  },
  created() {
    this.getRoleMenu();
    // this.getPending();
    // this.getEvaluationself();
  },
};
</script>
