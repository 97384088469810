<template>
  <div class="layout">
    <SiderBar />

    <div class="site-content">
      <Navbar />

      <div class="content">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.layout {
  display: flex;
  // min-height: 100%;
  height: 100vh;
}

.site-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  height: 100vh;
  overflow: auto;
  background-image: url("../../assets/Images/bg.jpg");
  background-position: bottom right;
  background-size: cover;
  background-repeat: no-repeat;

  .content {
    height: 100%;
    padding: 20px 60px;

    @media screen and (max-width: 767px) {
        padding: 10px;
    }
  }
}
</style>

<script>
import SiderBar from './SideBar.vue';
import Navbar from './Navber.vue';

export default {
  components: {
    SiderBar,
    Navbar,
  },
  created() {
    const myCookie = document.cookie.replace(
      /(?:(?:^|.*;\s*)DdmcToken\s*=\s*([^;]*).*$)|^.*$/,
      '$1',
    );
    // console.log('myCookie', myCookie)
    this.$http.defaults.headers.common.Authorization = myCookie;
  },
};
</script>
