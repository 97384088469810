<template>
  <div class="Navbar">
    <div class="center-content">
      <div class="welcome-block">
        <!-- <h3>
                    Hello! 黃曉明 協理
                </h3> -->
        <span> {{i18n('Custom.Welcome')}} </span>
        <div class="lang-wrap">
          <svg width="33" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M0 128C0 92.7 28.7 64 64 64H256h48 16H576c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H320 304 256 64c-35.3 0-64-28.7-64-64V128zm320 0V384H576V128H320zM178.3 175.9c-3.2-7.2-10.4-11.9-18.3-11.9s-15.1 4.7-18.3 11.9l-64 144c-4.5 10.1 .1 21.9 10.2 26.4s21.9-.1 26.4-10.2l8.9-20.1h73.6l8.9 20.1c4.5 10.1 16.3 14.6 26.4 10.2s14.6-16.3 10.2-26.4l-64-144zM160 233.2L179 276H141l19-42.8zM448 164c11 0 20 9 20 20v4h44 16c11 0 20 9 20 20s-9 20-20 20h-2l-1.6 4.5c-8.9 24.4-22.4 46.6-39.6 65.4c.9 .6 1.8 1.1 2.7 1.6l18.9 11.3c9.5 5.7 12.5 18 6.9 27.4s-18 12.5-27.4 6.9l-18.9-11.3c-4.5-2.7-8.8-5.5-13.1-8.5c-10.6 7.5-21.9 14-34 19.4l-3.6 1.6c-10.1 4.5-21.9-.1-26.4-10.2s.1-21.9 10.2-26.4l3.6-1.6c6.4-2.9 12.6-6.1 18.5-9.8l-12.2-12.2c-7.8-7.8-7.8-20.5 0-28.3s20.5-7.8 28.3 0l14.6 14.6 .5 .5c12.4-13.1 22.5-28.3 29.8-45H448 376c-11 0-20-9-20-20s9-20 20-20h52v-4c0-11 9-20 20-20z"/></svg>
        <select
            class="changeLang"
            v-model="language"
            @change="setLang(language)"
          >
            <option
              v-for="item in enabledLangOptions"
              :key="item.LocalizationTypeCode"
              :value="item"
            >
              {{ item.LocalizationTypeName }}
            </option>
          </select>
        </div>

        <button class="account-btn">
          <!-- <img src="@/assets/Images/head2.png" alt="" /> -->

          {{ user.EmpCName }} {{ user.TitleName }}

          <i class="el-icon-s-tools"></i>
          <ul>
            <li>
              <a href="#" v-if="!isAD" @click.prevent="setPassword">{{i18n('Custom.ChangePassword')}}</a>
              <router-link to="/" @click.native="logout">{{i18n('Custom.SignOut')}}</router-link>
            </li>
          </ul>
        </button>

        <!-- <a class="logout" href=""><i class="fas fa-sign-out-alt"></i> 登出</a> -->
      </div>
    </div>

    <!-- Modal -->
    <div class="modal fade" id="editPasswordModal" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <form @submit.prevent="change" class="modal-content">
          <div class="modal-header">{{i18n('Custom.ChangePassword')}}</div>

          <div class="modal-body">
            <div class="row">
              <div class="col-md-12">
                <div class="field">
                  <div class="field-name">{{i18n('Custom.PleaseEnterOldPassword')}}</div>
                  <!-- <input name="email" id="email" type="text" minLength="5" required> -->
                  <input
                    type="password"
                    name="password"
                    value=""
                    v-model="changeInfo.password"
                    required
                  />
                </div>
              </div>

              <div class="col-md-12">
                <div class="field">
                  <div class="field-name">{{i18n('Custom.PleaseEnterNewPassword')}}</div>
                  <input
                    type="password"
                    name="name"
                    value=""
                    v-model="changeInfo.newPassword"
                    minlength="8"
                    required
                  />
                </div>
              </div>

              <div class="col-md-12">
                <div class="field">
                  <div class="field-name">{{i18n('Custom.PleaseEnterNewPasswordAgain')}}</div>
                  <input
                    type="password"
                    name="name"
                    value=""
                    v-model="changeInfo.confirmationPassword"
                    minlength="8"
                    required
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="modal-footer">
            <!-- <button type="submit" class="btn btn-blue">submit</button> -->
            <button type="submit" class="btn btn-blue">{{i18n('Custom.Send')}}</button>
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              {{i18n('Basic.Cancel')}}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>


.Navbar {
  @media screen and (max-width: 767px) {
        height: 50px;
        padding-left: 60px;
        background-color: #FFF;
    }
}

.lang-wrap{
  border: 1px solid #000;
  border-radius:25px;
  padding: 2px 10px;
  select{
    cursor: pointer;
    border: none;
    vertical-align: middle;
  }
  svg{
    vertical-align: middle;
  }
}

.account-btn {
  outline: 0;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  background: #728cf1;
  border: 0;
  border-radius: 4px;
  box-shadow: 0 4px 12px rgba(#000, 0.1);
  padding: 5px;
  color: #fff;
  border-radius: 25px;
  position: relative;
  cursor: pointer;
  z-index: 9;

  height: 45px;
  padding: 0 12px;

  &:focus,
  &:active {
    ul {
      transform: translate(0, 20px);
      opacity: 1;
      visibility: visible;
    }
    i {
      transform: rotate(180deg);
    }
  }

  img {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin-right: 10px;
    background-color: #fff;
    // animation: ripple 0.6s linear infinite;
  }

  .text-wrap {
    text-align: left;
    font-family: "Roboto", sans-serif !important;
    line-height: 75%;
    .name {
      font-size: 0.8em;
    }
    span {
      font-size: 0.7em;
      opacity: 0.5;
    }
  }

  i {
    margin-left: 10px;
    margin-right: 5px;
    font-size: 0.8em;
    transition: all 0.3s;
  }

  ul {
    position: absolute;
    top: 100%;
    right: 0;
    background: #fff;
    width: 100%;
    border-radius: 4px;
    box-shadow: 0 4px 12px rgba(#000, 0.1);
    text-align: left;
    opacity: 0;
    visibility: hidden;
    transition: 0.3s ease;
    padding-left: 10px;

    &:before {
      content: "";
      position: absolute;
      top: -6px;
      left: 20px;
      width: 0;
      height: 0;
      box-shadow: 2px -2px 6px rgba(#000, 0.05);
      border-top: 6px solid #fff;
      border-right: 6px solid #fff;
      border-bottom: 6px solid transparent;
      border-left: 6px solid transparent;
      transform: rotate(-45deg);
      mix-blend-mode: multiple;
    }

    li {
      z-index: 1;
      position: relative;
      background: #fff;
      padding: 0 10px;
      color: #666;
      list-style: none;

      &.active {
        color: #5380f7;
      }

      &:first-child {
        border-radius: 4px 4px 0 0;
      }

      &:last-child {
        border-radius: 0 0 4px 4px;

        a {
          border-bottom: 0;
        }
      }
    }

    a {
      display: block;
      border-bottom: 1px solid rgba(#000, 0.05);
      padding: 16px 0;
      color: inherit;
      font-size: 10px;
      text-decoration: none;
    }
  }
}

.welcome-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

svg:not(:root).svg-inline--fa {
  margin-left: 10px;
}

.btn-blue {
  color: #fff !important;
  font-size: 0.85em;
  background: #343b55;
  height: 38px;
}
</style>

<script>
import $ from 'jquery';
import { mapState, mapActions } from 'pinia'; // 引入映射函数
import { languageStore } from '@/stores/languageStore';

export default {
  data() {
    return {
      user: {},
      changeInfo: {},
      isAD: false,
      language: null,
    };
  },
  methods: {
    ...mapActions(languageStore, ['getEnabledLanguageList']), // 映射action
    ...mapActions(languageStore, ['setLanguage']), // 映射action

    // 多國語系
    i18n(keyStr) {
      return this.$t(keyStr);
    },

    async setLang() {
      const value = this.language;
      console.log('value', value);
        await import(`../../../public/i18n/${value.LocalizationTypeCode}.json`)
        .then((res) => {
          this.$i18n.setLocaleMessage(value.LocalizationTypeCode, res);
        })
        .catch(() => {});

      this.setLanguage(value);
      this.$i18n.locale = value.LocalizationTypeCode;
    },

    logout() {
      // this.$router.push("/");
      this.$store.dispatch('alertMessageModule/updateMessage', {
        message: '已成功登出',
        status: 'success',
      });
      localStorage.clear();
    },
    setPassword() {
      $('#editPasswordModal').modal('show');
    },
    change() {
      const url = `${window.BaseUrl.api}/user/ChangePasswordByself`;

      const obj = {
        Account: this.changeInfo.Account,
        CurrentPassword: this.changeInfo.password,
        NewPassword: this.changeInfo.confirmationPassword,
      };

      // console.log('obj', obj)

      this.$http
        .post(url, obj)
        .then((response) => {
          if (response.data.ErrorMessage) {
            this.$notify({
              title: '錯誤',
              message: response.data.ErrorMessage,
              type: 'error',
              duration: 0,
            });
          } else {
            this.$store.dispatch('alertMessageModule/updateMessage', {
              message: '成功變更密碼',
              status: 'success',
            });
            $('#editPasswordModal').modal('hide');
          }
        })
        .catch((error) => {
          this.$notify({
            title: '錯誤',
            message: error.response.data.ErrorMessage,
            type: 'error',
            duration: 0,
          });
        });
    },
  },

  computed: {
    ...mapState(languageStore, ['enabledLangOptions']),
    ...mapState(languageStore, ['currentLanguage']),
  },

  created() {
    this.user = {
      EmpCName: localStorage.Name,
    };

    this.getEnabledLanguageList();
    this.language = this.currentLanguage;
    // const emp = localStorage.EmpNo.substring(3, 8);
    // this.changeInfo = {
    //   Account: `${localStorage.CompId}${emp}`,
    // };

    // this.isAD = localStorage.IsAD === 'true';
  },
};
</script>
