var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"side-menu",class:{'open':_vm.menuIsOpen}},[_c('div',{staticClass:"burger",on:{"click":function($event){_vm.menuIsOpen=!_vm.menuIsOpen}}},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","height":"1em","viewBox":"0 0 448 512"}},[_c('path',{attrs:{"fill":"#FFF","d":"M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z"}})])]),_c('div',{staticClass:"side-menu-inner"},[_vm._m(0),_vm._l((_vm.menus),function(menu,index){return _c('div',{key:menu.icon,staticClass:"main-item"},[_c('a',{staticClass:"had-sub-menu",class:{ active: _vm.toggle[menu.Guid] },attrs:{"href":`#menu-${index}`,"role":"button","aria-controls":`#menu-${index}`,"data-toggle":"collapse","aria-expanded":"false"}},[_vm._l((menu.Localizationals),function(language){return [(
               language.LocalizationTypeCode === _vm.currentLanguage.LocalizationTypeCode
             )?_c('span',{key:language.LocalizationTypeCode},[_vm._v(" "+_vm._s(language.MenuName ? language.MenuName : menu.MenuName)+" ")]):_vm._e()]})],2),_c('div',[_c('ul',{staticClass:"collapse list-unstyled",attrs:{"id":`menu-${index}`}},_vm._l((menu.Children),function(item){return _c('li',{key:item.Guid,staticClass:"menu-item"},[_c('div',{staticClass:"icon-wrap",class:{ active: `${item.Link}` === _vm.$route.path }},[_c('i',{staticClass:"el-icon-caret-right"})]),_c('router-link',{attrs:{"to":item.Link}},[_vm._l((item.Localizationals),function(language){return [(
                     language.LocalizationTypeCode ===
                     _vm.currentLanguage.LocalizationTypeCode
                   )?_c('span',{key:language.LocalizationTypeCode},[_vm._v(" "+_vm._s(language.MenuName ? language.MenuName : item.MenuName)+" ")]):_vm._e()]})],2)],1)}),0)])])})],2)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"site-name"},[_vm._v(" 人格特質"),_c('br'),_vm._v("測驗系統 ")])
}]

export { render, staticRenderFns }